import { Forms } from "src/ui/features";
import { Layouts } from "src/ui/pages/components";
// TODO: replace mp4's with gifs
export default function LoginPage() {
  return (
    <>
      <Layouts.Simple>
        <Forms.LoginForm />
        <div
          className="dugeonCrt"
          style={{
            backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/d3Cult-CRT.gif')`,
          }}
        />
      </Layouts.Simple>
    </>
  );
}
