import { Suspense, lazy } from "react";
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Pages } from "src/ui/pages";
import { PreLoader } from "src/ui/components";
import ProtectedRoute from "../ProtectedRoute";
import { IUserRoles } from "src/types/IUserRoles.types";
import { Menus } from "src/ui/features";
const App = lazy(() => import("src/App"));

export default function RouteConfig() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Suspense fallback={<PreLoader />}>
            <App />
          </Suspense>
        }
      >
        <Route index element={<Pages.Meme />} />
        <Route path="dungeon" element={<Pages.DungeonPage />} />
        <Route path="shitshow" element={<Pages.Shitshow />} />
        <Route path="login">
          <Route index element={<Pages.Login.LoginPage />}></Route>
          <Route
            path="forgotPassword"
            element={<Pages.Login.ForgotPassword />}
          ></Route>
          <Route path="otp" element={<Pages.Login.OneTimePasscode />} />
        </Route>
        <Route path="register" element={<Pages.RegisterPage />} />
        <Route element={<ProtectedRoute roles={[IUserRoles.USER]} />}>
          <Route path="user/:userId" element={<Pages.UserPage />} />
        </Route>
        <Route element={<Pages.Layouts.ProfilePage />}>
          <Route
            path="profile"
            element={<ProtectedRoute roles={[IUserRoles.USER]} />}
          >
            <Route index element={<Pages.ProfilePage />} />
            <Route path="edit" element={<Pages.Edit.ProfilePage />} />
            <Route path="password/edit" element={<Pages.Edit.PasswordPage />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute roles={[IUserRoles.ADMIN]} />}>
          <Route path="admin" element={<Pages.Layouts.ProfilePage />}>
            <Route path="roles/edit" element={<Pages.Edit.RolePage />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute roles={[IUserRoles.GOD]} />}>
          <Route element={<Pages.Layouts.ProfilePage />}>
            <Route path="users" element={<Pages.UsersPage />}>
              <Route path=":userId" element={<Menus.EditUser />} />
            </Route>
          </Route>
        </Route>
        <Route element={<ProtectedRoute roles={[IUserRoles.USER]} />}>
          <Route path="chat" element={<Pages.Layouts.ChatLayout />}>
            <Route index element={<Pages.Chat.DefaultPage />} />
            <Route path=":userId" element={<Pages.Chat.Messages />} />
          </Route>
        </Route>
        <Route path="unauthorized" element={<Pages.UnauthorizedPage />} />
        <Route path="*" element={<Pages.NotFoundPage />} />
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}
