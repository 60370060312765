import { Chat } from "src/ui/components";
import { TypewriterTextEffect } from "src/ui/features/TextEffects";
import { TYPEWRITER_ANIMATION } from "src/ui/features/TextEffects/Typewriter/Typewriter.constants";
import { BOTTOM_LINE, TOP_LINE } from "./Chat.constants";
import "./Chat.styles.scss";

export default function DroogAiChatPlaceholder() {
  return (
    <>
      <Chat.Placeholder>
        <div className="droogMessage">
          <TypewriterTextEffect>
            <h2
              style={{
                animation: TYPEWRITER_ANIMATION.ANIMATION1,
                width: "260px",
              }}
            >
              {TOP_LINE}
            </h2>
          </TypewriterTextEffect>
          <img
            alt="Degen dungeon head"
            className="degenHead"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/d3c/degenHead.svg`}
          />
          <TypewriterTextEffect>
            <h2
              style={{
                animation: TYPEWRITER_ANIMATION.ANIMATION2,
                width: "0",
              }}
            >
              {BOTTOM_LINE}
            </h2>
          </TypewriterTextEffect>
        </div>
      </Chat.Placeholder>
    </>
  );
}
