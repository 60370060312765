import LayoutProps from "./Layout.types";
import { Top, Middle, Left, Center, Right, Bottom } from "./components";
import TopProps from "./components/Top/Top.types";
import MiddleProps from "./components/Middle/Middle.types";
import LeftProps from "./components/Left/Left.types";
import MainProps from "./components/Center/Center.types";
import RightProps from "./components/Right/Right.types";
import BottomProps from "./components/Bottom/Bottom.types";
import "./Layout.styles.scss";

function Layout({ children }: LayoutProps) {
  document.body.className = "";
  return (
    <div className={`layout dungeon`} data-testid="layoutV2">
      {children}
    </div>
  );
}

Layout.Top = function ({ children }: TopProps) {
  return <Top>{children}</Top>;
};

Layout.Middle = function ({ children }: MainProps) {
  return <Middle>{children}</Middle>;
};

Layout.Bottom = function ({ children }: BottomProps) {
  return <Bottom>{children}</Bottom>;
};

Layout.Left = function ({ children }: LeftProps) {
  return <Left>{children}</Left>;
};

Layout.Center = function ({ children }: MiddleProps) {
  return <Center>{children}</Center>;
};

Layout.Right = function ({ children }: RightProps) {
  return <Right>{children}</Right>;
};

export default Layout;
