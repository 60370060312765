import { SiteCopyright, Layout } from "src/ui/components";

export default function Footer() {
  return (
    <Layout.Bottom>
      <Layout.Middle>
        <Layout.Left>
          <SiteCopyright />
        </Layout.Left>
        <Layout.Center>{/* <Icons.Footer /> */}</Layout.Center>
        <Layout.Right>
          <div />
        </Layout.Right>
      </Layout.Middle>
    </Layout.Bottom>
  );
}
