import { Meme } from "src/ui/features";
import { Layouts } from "../components";
export default function DungeonPage() {
  return (
    <Layouts.DefaultPage>
      <div
        className="d3CultLogo"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/d3CultLogo.gif')`,
        }}
      />
      <Meme.Tickers.JoinNow reverse={true} />
    </Layouts.DefaultPage>
  );
}
