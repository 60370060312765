import FooterProps from "./Footer.types";
import "./Footer.styles.scss";

export default function Footer({ children }: FooterProps) {
  return (
    <div className="footer">
      <div className="column">{children}</div>
      <div className="column">
        <img
          alt="Duckboi is flying forever"
          className="duckboiFlying"
          src={`${process.env.REACT_APP_CDN_HOST}/assets/gifs/duckboi.flying.forever.gif`}
          width="50%"
        />
      </div>
      <div className="column">
        <img
          className="monogram"
          src={`${process.env.REACT_APP_CDN_HOST}/assets/monograms/db.svg`}
          width="30%"
          alt="DB monogram"
        />
      </div>
    </div>
  );
}
