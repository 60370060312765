import Countdown from "../Countdown";
import { BUZZWORDS, LAUNCH_DATE } from "./BannerGrid.constants";
import "./BannerGrid.styles.scss";

export default function BannerGrid() {
  return (
    <div className="banners">
      <div className="grid">
        <div className="row">
          <div className="one">
            <div className="quadrant">
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/assets/ripoffs/rageAgainstTheMachine.png`}
                className="degenEmpire"
                alt="Duckboi rips off Rage's album cover for Evil Empire"
              />
            </div>
            <div className="quadrant">
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/assets/ripoffs/superShittyShitshow.png`}
                alt="Duckboi rips off the start screen of Super Mario Bros"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="two">
            <div className="quadrant">
              <p>{BUZZWORDS}</p>
            </div>
            <div className="quadrant">
              <h1>Stay Tuned</h1>
              <Countdown launchDate={LAUNCH_DATE} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="four">
            <div className="quadrant">
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/assets/shitshow/banner.svg`}
                alt="Shitshow is a raunchy bi-weekly web comic about a lame duck trapped in a post-modern cluster-fuck"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="three">
            <div className="quadrant">
              <img
                src={`${process.env.REACT_APP_CDN_HOST}/assets/artwork/shitshow.svg`}
                alt="Duckboi walking through a shadey back alley. He walks past a homeless Pepe, shitting doge, and roadkill popcat."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
