import "./Carousel.styles.scss";

export default function Carousel() {
  return (
    <div className="carousel">
      <div className="slides">
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/axeMurderer.svg`}
            alt="Duckboi pretends to be Jack Torrence the axe murderer from the movie called, The Shining"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/bullish.svg`}
            alt="Duckboi is feeling bullish so he is wearing his bull helmet with his golden bull pendant"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/droog.svg`}
            alt="Duckboi is going through the Ludivico process and holding the porcelain penis sculpture that Alex killed the old cat lady with"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/falseidol.svg`}
            alt="Duckboi is holding the coveted moonman award and sporting his fake MTV hairdo. He is also wearing his corporate fuck you necktie with his fake Off-white sneakers."
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/fastfoodJunky.svg`}
            alt="Duckboi is wearing his Booger King crown and about to eat some french fries. He is wearing his burger tie and his fake BK Nikes."
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/gameover.svg`}
            alt="Duckboi is wearing his TanookAnarchy cap with his Retardo Power Trip glove and some fake PS Nikes"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/grunge.svg`}
            alt="Duckboi is Robo-tripping and about to swallow a Nirvana, happy-face tab. Duckboi's grungy, greasy hair matches his flannel necktie. He has Kurt Cobain's favorite sneakers"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/hoodlum.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/jerkoff.svg`}
            alt="Duckboi has a blow-up doll and about to swallow a boner pill. Duckboi has a banana peel head with a banana necktie. Duckboi has some hater blockers on with some PornHub Nikes"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/loon.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/metalhead.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/moviebuff.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/og.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/popcat.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/punkass.square.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/redneck.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/skaterat.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/dreamSlasher.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/brat.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/sweettooth.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/spoiled.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/trash.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
      </div>
      <div className="slides">
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/axeMurderer.svg`}
            alt="Duckboi pretends to be Jack Torrence the axe murderer from the movie called, The Shining"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/bullish.svg`}
            alt="Duckboi is feeling bullish so he is wearing his bull helmet with his golden bull pendant"
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/droog.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/falseidol.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/fastfoodJunky.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/gameover.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/grunge.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/hoodlum.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/jerkoff.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/loon.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/metalhead.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/moviebuff.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/og.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/popcat.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/punkass.square.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/redneck.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/skaterat.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/dreamSlasher.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/brat.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/sweettooth.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/spoiled.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
        <div className="frame">
          <img
            className="slide"
            src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/trash.svg`}
            alt=""
          />
          <div className="backdrop" />
        </div>
      </div>
    </div>
  );
}
