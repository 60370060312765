import { Ticker } from "src/ui/components";
import "./JoinNow.styles.scss";
import { TICKER_TEXT } from "./JoinNow.constants";

export default function JoinNow({ reverse = false }: { reverse?: boolean }) {
  return (
    <Ticker
      classNames="joinnow"
      borderColor="#fff4b4"
      backgroundColor="#000"
      color="#fff4b4"
      reverse={reverse}
    >
      <div className="tick">
        <span>{TICKER_TEXT.BANNER1}</span>
      </div>
      <div className="tick">
        <span>{TICKER_TEXT.BANNER2}</span>
      </div>
      <div className="tick">
        <span>{TICKER_TEXT.BANNER1}</span>
      </div>
      <div className="tick">
        <span>{TICKER_TEXT.BANNER2}</span>
      </div>
      <div className="tick">
        <span>{TICKER_TEXT.BANNER1}</span>
      </div>
      <div className="tick">
        <span>{TICKER_TEXT.BANNER2}</span>
      </div>
    </Ticker>
  );
}
