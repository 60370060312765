import TickerProps from "./Ticker.types";
import "./Ticker.styles.scss";

export default function Ticker({
  backgroundColor,
  borderColor,
  children,
  classNames,
  color,
  reverse = false,
}: TickerProps) {
  const direction = reverse ? "ticks reverse" : "ticks";
  classNames = classNames ? `ticker ${classNames}` : "ticker";
  return (
    <div className={classNames} style={{ borderColor, backgroundColor, color }}>
      <div className={direction}>{children}</div>
      <div className={direction}>{children}</div>
    </div>
  );
}
