import { Social } from "src/ui/components";
import {
  faDiscord,
  faInstagram,
  faRedditAlien,
  faTelegramPlane,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { SOCIALMEDIA_URIS } from "./SocialMedia.constants";
import "./SocialMedia.styles.scss";

export default function SocialMedia() {
  return (
    <div className="socialmedia">
      <Social href={SOCIALMEDIA_URIS.TWITTER} icon={faXTwitter} size="3x" />
      <Social href={SOCIALMEDIA_URIS.DISCORD} icon={faDiscord} size="3x" />
      <Social href={SOCIALMEDIA_URIS.INSTAGRAM} icon={faInstagram} size="3x" />
      <Social href={SOCIALMEDIA_URIS.REDDIT} icon={faRedditAlien} size="3x" />
      <Social
        href={SOCIALMEDIA_URIS.TELEGRAM}
        icon={faTelegramPlane}
        size="3x"
      />
    </div>
  );
}
