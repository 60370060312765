import { LegacyRef } from "react";
import { useCountdown, useOtp } from "src/hooks";
import { Layouts } from "src/ui/pages/components";
import { Wrapper } from "src/ui/components";
import "./OneTimePasscode.styles.scss";

export default function OneTimePasscode() {
  const { countdown } = useCountdown();
  const { inputRef, otp } = useOtp(countdown === 0);
  const countdownMessage =
    countdown > 0 ? `This code expires: ${countdown}` : "Time expired";

  return (
    <Layouts.Simple>
      <Wrapper classNames="auth" headerText="Enter onetime passcode">
        <h3>{countdownMessage}</h3>
        <form className="otp">
          {[0, 1, 2, 3, 4, 5].map((el, index) => (
            <input
              className="input"
              disabled={countdown === 0}
              key={index}
              name="passCode"
              onChange={() => {}}
              ref={inputRef as LegacyRef<HTMLInputElement> | undefined}
              value={otp[index] === undefined ? "" : otp[index]}
            />
          ))}
        </form>
      </Wrapper>
      <div
        className="dugeonCrt"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/d3Cult-CRT.gif')`,
        }}
      />
    </Layouts.Simple>
  );
}
