import { Breadcrumbs } from "src/ui/components";
import { useLocation } from "react-router-dom";
import "./Site.styles.scss";

export default function SiteToolbar() {
  const location = useLocation();

  return location.pathname !== "/dungeon" ? (
    <div className="siteToolbar" data-testid="siteToolBar">
      <Breadcrumbs />
    </div>
  ) : null;
}
