import { Forms } from "src/ui/features";
import Button from "../Button";
import { JOIN_THE_CULT } from "./Cult.constants";
import "./Cult.styles.scss";

export default function Cult() {
  return (
    <div
      className="cult"
      id="cult_asas8382asd"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/logos/d3c.svg')`,
      }}
    >
      <img
        className="dddc"
        src={`${process.env.REACT_APP_CDN_HOST}/assets/logos/d3c.svg`}
        alt="Duckboi Degen Dungeon Cult"
      />
      <Forms.JoinNowForm>
        <Button.Submit text={JOIN_THE_CULT.TEXT} classNames="joinNow jello" />
      </Forms.JoinNowForm>
    </div>
  );
}
