import "./Shitshow.styles.scss";

export default function Shitshow() {
  return (
    <div
      className="shitshow stayTuned"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/stayTuned.gif')`,
      }}
    />
  );
}
