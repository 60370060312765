import { SiteMenuCloseIcon } from "src/ui/features/Icons";
import { Backdrop } from "src/ui/components";
import { Forms } from "src/ui/features";
import "./EditUser.styles.scss";
import { useNavigate } from "react-router-dom";

function EditUserHeader({ closeModal }: { closeModal: () => void }) {
  return (
    <div className="userEditFormHeader" data-testid="siteHeaderMenu">
      <h3 className="userEditFormHeaderTitle">Edit User</h3>
      <SiteMenuCloseIcon callback={closeModal} />
    </div>
  );
}

export default function EditUserSidebar() {
  const navigate = useNavigate();

  function closeModal() {
    navigate("/users");
  }

  return (
    <>
      <div className="editUser show" data-testid="siteMenu">
        <EditUserHeader closeModal={closeModal} />
        <Forms.EditUserForm />
      </div>
      <Backdrop show={true} handleClick={closeModal} />
    </>
  );
}
