import { Link } from "react-router-dom";
import LogoProps from "./Logo.types";
import { SITE_MENU_LINKS } from "src/ui/features/Menus/Site/Site.constants";
import "./Logo.styles.scss";

export default function Logo({ children, ...props }: LogoProps) {
  const { classNames, logoText } = props;
  const className = classNames ? `logo ${classNames}` : "logo";
  return (
    <Link
      className={className}
      data-testid="logo"
      to={SITE_MENU_LINKS[0].to || ""}
    >
      <div>{children}</div>
      {logoText && <div className="brand">{logoText}</div>}
    </Link>
  );
}
