import "./Hero.styles.scss";

export default function Hero() {
  console.log("env", process.env);
  return (
    <section
      className="hero"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/starburst.gif')`,
      }}
    >
      <div className="center">
        <img
          className="logo"
          src={`${process.env.REACT_APP_CDN_HOST}/assets/logos/duckboi.svg`}
          alt="Duckboi logo"
        />
        <img
          className="duckboi"
          src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/ogRetarded.svg`}
          alt="Duckboi is retarded"
        />
        <img
          className="tagline"
          src={`${process.env.REACT_APP_CDN_HOST}/assets/taglines/superPremiumShitcoin.svg`}
          alt="Super Premium Shitcoin"
        />
      </div>
    </section>
  );
}
