import { Link } from "react-router-dom";
import { ScrollToAnchor } from "src/ui/components";
import "./Footer.styles.scss";

export default function Footer() {
  return (
    <div className="footerLinks">
      <div>
        <ScrollToAnchor />
        <Link to="#tokenomics_1e2fe1q">Tokenomics</Link>
        <Link to="#whitepaper_asdo9d99fu">Whitepaper</Link>
        <Link to="#cult_asas8382asd">Join The Cult</Link>
      </div>
      <div>
        <Link to="/dungeon">Cult Login</Link>
        <Link to="/shitshow">Shitshow</Link>
      </div>
    </div>
  );
}
