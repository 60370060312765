import { Meme as M } from "..";
import { VIEW_WHITEPAPER } from "./Whitepaper.constants";
import "./Whitepaper.styles.scss";

export default function Whitepaper() {
  return (
    <div id="whitepaper_asdo9d99fu" className="whitepaper">
      <div className="banner">
        <img
          className="punkass"
          src={`${process.env.REACT_APP_CDN_HOST}/assets/duckbois/punkass.svg`}
          alt="Duckboi is a punkass"
        />
        <div className="callToAction">
          <div>
            <img
              className="nmTheWp"
              src={`${process.env.REACT_APP_CDN_HOST}/assets/whitepaper/nmTheWp.mobile.svg`}
              alt="Nevermind the Whitepaper"
            />
          </div>
          <div>
            <M.Button.LinkButton
              backgroundColor="#ed166d"
              borderColor="#f0e912"
              color="#f0e912"
              classNames="jello"
              text={VIEW_WHITEPAPER.TEXT}
              uri={VIEW_WHITEPAPER.URI}
            />
          </div>
        </div>
      </div>
      <div
        className="ripped"
        style={{
          backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/whitepaper/ripped.svg')`,
        }}
      />
    </div>
  );
}
