import DegenProps from "./Degen.types";
import { Logo } from "src/ui/components";
import "./Degen.styles.scss";

export default function Degen({ classNames, logoText }: DegenProps) {
  return (
    <Logo classNames={classNames} logoText={logoText}>
      <img
        src={`${process.env.REACT_APP_CDN_HOST}/assets/d3c/degenHead.svg`}
        alt="Degen dungeon head logo"
      />
    </Logo>
  );
}
