import { createApi } from "@reduxjs/toolkit/query/react";
import { setUsers, setUserSearch } from "src/redux/features/users.slice";
import customFetchBase from "./customFetchBase";
import { IUser } from "src/types/IUser.types";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: customFetchBase,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query<IUser[], void>({
      query() {
        return {
          url: "/users",
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (result: { data: { users: IUser[] } }) =>
        result.data.users,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUsers(data));
        } catch (error) {}
      },
      providesTags: ["Users"],
    }),
    searchUsers: builder.query<Partial<IUser>[], string>({
      query(name) {
        return {
          url: `users/search/${name}`,
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (result: { data: { users: Partial<IUser>[] } }) =>
        result.data.users,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserSearch(data));
        } catch (error) {}
      },
      providesTags: ["Users"],
    }),
    updateUserRoles: builder.mutation<IUser, { id: string; roles: string[] }>({
      query(data) {
        const { id } = data;
        return {
          credentials: "include",
          url: `user/${id}/roles`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Users" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useSearchUsersQuery,
  useUpdateUserRolesMutation,
} = usersApi;
