import { Chat } from "./Chat";
import { Edit } from "./Edit";
import DungeonPage from "./Dungeon";
import { Layouts } from "./components";
import { Login } from "./Login";
import NotFoundPage from "./NotFound";
import ProfilePage from "./Profile";
import RatingPage from "./Rating";
import RegisterPage from "./Register";
import UnauthorizedPage from "./Unauthorized";
import UserPage from "./User";
import UsersPage from "./Users";
import Meme from "./Meme";
import Shitshow from "./Shitshow";

export const Pages = {
  Chat,
  Meme,
  Edit,
  DungeonPage,
  Layouts,
  Login,
  NotFoundPage,
  ProfilePage,
  RatingPage,
  RegisterPage,
  Shitshow,
  UnauthorizedPage,
  UserPage,
  UsersPage,
};
