import { Menus } from "../Menus";
import "./FollowMe.styles.scss";

export default function FollowMe() {
  return (
    <div
      className="followMe"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/starburst.gif')`,
      }}
    >
      <div className="center">
        <img
          src={`${process.env.REACT_APP_CDN_HOST}/assets/taglines/followMe.svg`}
          alt="Follow Me"
        />
        <Menus.SocialMedia />
      </div>
    </div>
  );
}
