import { useSelector } from "react-redux";
import { Meme as M } from "src/ui/features/Meme";
import { Modals } from "src/ui/features";
import { useState } from "react";
import "./Meme.styles.scss";

export default function Meme() {
  const [toggleModal, setToggleModal] = useState(false);
  const meme = useSelector((state: any) => state.uiState.meme);
  return (
    <div className={`meme ${meme}`}>
      <Modals.ContactModal
        showModal={toggleModal}
        handleCloseModal={() => setToggleModal(false)}
      />
      <M.Hero />
      <M.Tokenomics />
      <M.Whitepaper />
      <M.Tickers.JoinNow />
      <M.Cult />
      <M.Tickers.JoinNow reverse={true} />
      <M.Carousel />
      <M.BannerGrid />
      <M.FollowMe />
      <M.Footer>
        <M.Menus.Footer />
      </M.Footer>
      <M.Copyright />
    </div>
  );
}
