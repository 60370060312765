import { getCopyrightYear } from "src/utils/date.utils";
import "./Copyright.styles.scss";
import { Icon } from "src/ui/components";
import { faPoo } from "@fortawesome/free-solid-svg-icons";

export default function Copyright() {
  return (
    <div className="copyright">
      <div className="column"></div>
      <div className="column">
        <Icon classNames="jello" icon={faPoo} size="2x" />
      </div>
      <div className="column">{`${getCopyrightYear()} Duckboi`}</div>
    </div>
  );
}
