import ContractAddressProps from "./ContractAddress.types";
import "./ContractAddress.styles.scss";
import { Icon } from "src/ui/components";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export default function ContractAddress({
  contractAddress,
  contractUri,
}: ContractAddressProps) {
  const first5Chars = contractAddress.slice(0, 5);
  const last5Chars = contractAddress.slice(-5);
  const truncatedAddress = `${first5Chars}...${last5Chars}`;
  return (
    <p className="contractAddress">
      <span>{truncatedAddress}</span>
      <a rel="noreferrer" target="_blank" href={contractUri}>
        <Icon icon={faExternalLink} size="1x" />
      </a>
    </p>
  );
}
