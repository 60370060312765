import { CONTRACT_ADDRESS, CONTRACT_URI } from "./Tokenomics.constants";
import ContractAddress from "../ContractAddress";
import "./Tokenomics.styles.scss";

export default function Tokenomics() {
  return (
    <section
      id="tokenomics_1e2fe1q"
      className="tokenomics"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_CDN_HOST}/assets/gifs/clouds.gif')`,
        backgroundColor: "#8dc0cc",
      }}
    >
      <img
        className="duckboiFlying"
        alt="Duckboi is flying then flies away. Then a Duckboi crypto coin pops up."
        src={`${process.env.REACT_APP_CDN_HOST}/assets/gifs/duckboi.flying.gif`}
      />
      <section className="details">
        <article>
          <h1>$DUCKBOI</h1>
          <ContractAddress
            contractAddress={CONTRACT_ADDRESS}
            contractUri={CONTRACT_URI}
          />
        </article>
        <article>
          <h2>Token Supply</h2>
          <p>1B</p>
        </article>
        <article>
          <h2>Liquidity</h2>
          <p>Burnt</p>
        </article>
        <article>
          <h2>Ownership</h2>
          <p>Renounced</p>
        </article>
      </section>
    </section>
  );
}
