import Button from "./Button";
import Carousel from "./Carousel";
import ContractAddress from "./ContractAddress";
import Copyright from "./Copyright";
import Countdown from "./Countdown";
import Cult from "./Cult";
import FollowMe from "./FollowMe";
import Footer from "./Footer";
import Hero from "./Hero";
import { Menus } from "./Menus";
import { Tickers } from "./Tickers";
import Tokenomics from "./Tokenomics";
import Whitepaper from "./Whitepaper";
import BannerGrid from "./BannerGrid/BannerGrid";

export const Meme = {
  BannerGrid,
  Button,
  Carousel,
  ContractAddress,
  Copyright,
  Countdown,
  Cult,
  FollowMe,
  Footer,
  Hero,
  Menus,
  Tickers,
  Tokenomics,
  Whitepaper,
};
