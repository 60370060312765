import ChatMessageProps from "./Message.types";
import { Chat } from "src/ui/components";
import "./Message.styles.scss";

export default function Message({
  createdAt,
  message,
  senderName,
  user,
}: ChatMessageProps) {
  const isReceiver = user && user!.name === senderName;
  const className = isReceiver ? "chatMessage receiver" : "chatMessage sender";
  return (
    <>
      <div className={className}>
        <div className="username">{isReceiver ? user!.name : senderName}</div>
        <span>{message}</span>
        <Chat.Timestamp createdAt={createdAt} />
      </div>
    </>
  );
}
