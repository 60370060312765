import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import { IMember } from "src/types/IMember.types";
import { JoinNowPayloadType } from "src/ui/features/Forms/JoinNow/JoinNow.schema";

export const memberApi = createApi({
  reducerPath: "memberApi",
  baseQuery: customFetchBase,
  tagTypes: ["Member"],
  endpoints: (builder) => ({
    memberJoin: builder.mutation<IMember, JoinNowPayloadType>({
      query(data) {
        return {
          url: "/member/join",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useMemberJoinMutation } = memberApi;
