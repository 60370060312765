import DroogAiChatPlaceholder from "src/ui/features/Placeholders";
import "./Default.styles.scss";

export default function DefaultChatPage() {
  return (
    <div className="defaultChatPage" data-testid="defaultChatPage">
      <DroogAiChatPlaceholder />
      {/* <PageTitle pageTitle="Chat with Others" />
      <Icon classNames="defaultPlaceholderIcon" icon={faComments} size="10x" /> */}
    </div>
  );
}
