import { ButtonProps, LinkButtonProps, LinkProps } from "./Button.types";
import { Link } from "react-router-dom";
import "./Button.styles.scss";

function Button({
  backgroundColor,
  borderColor,
  classNames,
  color,
  onClick,
  text,
}: ButtonProps) {
  classNames = classNames ? `button ${classNames}` : "button";
  return (
    <div
      className={classNames}
      onClick={onClick}
      style={{ borderColor, backgroundColor, color }}
    >
      {text}
    </div>
  );
}

Button.Submit = function LinkButton({
  backgroundColor,
  borderColor,
  classNames,
  color,
  text,
}: ButtonProps) {
  classNames = classNames ? `button ${classNames}` : "button";
  return <button className={classNames}>{text}</button>;
};

Button.Link = function LinkButton({
  backgroundColor,
  borderColor,
  classNames,
  color,
  to,
  text,
}: LinkProps) {
  classNames = classNames ? `link ${classNames}` : "link";
  return (
    <Link
      className={classNames}
      style={{ backgroundColor, borderColor, color }}
      to={to}
    >
      {text}
    </Link>
  );
};

Button.LinkButton = function LinkButton({
  backgroundColor,
  borderColor,
  color,
  classNames,
  text,
  uri,
}: LinkButtonProps) {
  classNames = classNames ? `link button ${classNames}` : "link button";
  return (
    <a
      className={classNames}
      href={uri}
      rel="noreferrer"
      style={{ borderColor, backgroundColor, color }}
      target="_blank"
    >
      {text}
    </a>
  );
};

Button.ButtonLink = function ButtonLink({
  backgroundColor,
  borderColor,
  classNames,
  color,
  onClick,
  text,
}: ButtonProps) {
  classNames = classNames ? `button ${classNames}` : "button";
  return (
    <button
      className={classNames}
      onClick={onClick}
      style={{ borderColor, backgroundColor, color }}
    >
      {text}
    </button>
  );
};

export default Button;
