import { ChatHeaderProps } from "../../Window.types";
import Icon from "src/ui/components/Icon";
import {
  faChevronDown,
  faChevronUp,
  faClose,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import "./Header.styles.scss";

export default function ChatWindowHeader({
  onClose,
  onMinimize,
  onMaximize,
  isMaximized,
}: ChatHeaderProps) {
  return (
    <div className="chatHeader">
      <div className="chatHeaderLeft">
        <Icon icon={faClose} size="xl" handleClick={onClose} />
        <Icon icon={faMinus} size="xl" handleClick={onMinimize} />
        {isMaximized ? (
          <Icon icon={faChevronDown} size="xl" handleClick={onMaximize} />
        ) : (
          <Icon icon={faChevronUp} size="xl" handleClick={onMaximize} />
        )}
      </div>
      <div className="chatHeaderRight" />
    </div>
  );
}
