import React from "react";
import { createRoot } from "react-dom/client";
import { store } from "src/redux/store";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { RouteConfig } from "./routing";

// ARTICLE: https://codevoweb.com/node-typescript-mongodb-jwt-authentication/

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <RouteConfig />
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
);
