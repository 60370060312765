import { PageTitle } from "src/ui/components";
import { Layouts } from "../components";

export default function NotFoundPage() {
  return (
    <Layouts.DefaultPage>
      <PageTitle pageTitle="404" />
      <img alt="Ludwig says nope" width="400" src="/assets/gifs/ludwig.gif" />
    </Layouts.DefaultPage>
  );
}
